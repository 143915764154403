<template>
	<div>
		<h2 class="my-3 text-center">{{$t('Member Level List')}}</h2>
		<!-- pricing plan cards -->
		<b-row class="level-card">
			<b-col
				offset-sm-2
				sm="10"
				md="12"
				offset-lg="2"
				lg="10"
				class="mx-auto"
			>
				<b-row>
					<b-col md="4" v-for="(item,index) in dataList" :key="index">
						<b-card align="center">
							<!-- img -->
							<b-img
								v-if="item.icon"
								:src="item.icon"
								class="icon"
								:class="{sm: index==0}"
								alt="basic svg img"
							/>
							<!--/ img -->
							<h3 class="my-3">{{ item.level }}</h3>
							<!-- <b-card-text>
								{{ pricing.basicPlan.subtitle }}
							</b-card-text> -->
							<!-- plan benefit -->
							<b-list-group class="list-group-circle text-left">
								<b-list-group-item>
									{{$t('Bandwidth Speed')}}：{{ item.speed }}Mbps
								</b-list-group-item>
								<b-list-group-item>
									<div class="d-flex align-items-center">
										<div class="flex-1">{{$t('Network Disk Capacity')}}：{{ item.capacity }}G</div>
										<b-badge variant="success" v-if="index==0">{{$t('Used')}}：3.9G</b-badge>
									</div>
								</b-list-group-item>
							</b-list-group>
							<!--/ plan benefit -->

							<!-- buttons -->
							<b-button block class="mt-2" variant="outline-success" v-if="index==0">
								{{$t('Your current level')}}
							</b-button>
							<!-- buttons -->
							<b-button block class="mt-2" variant="primary" v-else>
								{{$t('Upgrade')}}
							</b-button>
						</b-card>
					</b-col>
					
				</b-row>
			</b-col>
		</b-row>
		<!--/ pricing plan cards -->

		<!-- <b-card :title="$t('Member Level Description')">
			<b-table
				ref="deviceList"
				class="position-relative"
				:items="dataList"
				stacked="sm"
				:fields="tableColumns"
				primary-key="id"
				hover
			>
				<template #cell(speed)="data">
					{{data.item.speed}}Mbps
				</template>
				<template #cell(capacity)="data">
					{{data.item.capacity}}G
				</template>
			</b-table>
		</b-card> -->
	</div>
	
</template>

<script>
import { BCard, BCardText, BTable,BRow,BCol,BListGroup,BListGroupItem,BButton,BImg,BBadge } from 'bootstrap-vue'

export default {
	components: {
		BCard,
		BCardText,
		BTable,BRow,BCol,BListGroup,BListGroupItem,BButton,BImg,BBadge
	},
	data () {
		return {
			currentLevel: '普通会员',
			tableColumns: [
				{ key: 'level', label:this.$t('Member Level') },
				{ key: 'speed', label: this.$t('Bandwidth Speed')},
				{ key: 'capacity', label: this.$t('Network Disk Capacity') }
			],
			dataList: [],
		}
	},
	created () {
		this.dataList = [{
			level: this.$t('level0'),
			speed: 5,
			capacity: 5,
			icon: require('@/assets/images/member/1.svg')
		},{
			level: this.$t('level1'),
			speed: 10,
			capacity: 50,
			icon: require('@/assets/images/member/2.svg')
		},{
			level: this.$t('level2'),
			speed: 20,
			capacity: 200,
			icon: require('@/assets/images/member/3.svg')
		},{
			level: this.$t('level3'),
			speed: 50,
			capacity: 1024,
			icon: require('@/assets/images/member/3.svg')
		},{
			level: this.$t('level4'),
			speed: 100,
			capacity: 5120,
			icon: require('@/assets/images/member/3.svg')
		}]
	}
}
</script>

<style lang="scss" scoped>
.level-card{
	.icon{
		height:104px;
		&.sm{
			height: 54px;
			margin-top: 50px;
		}
	}
}
</style>
